import { useMemo } from 'react';
import Link from './link';
import Header from './header';
import UptimeRobot from './uptimerobot';
import Package from '../../package.json';

function App() {

  const apikeys = useMemo(() => {
    const { ApiKeys } = window.Config;
    if (Array.isArray(ApiKeys)) return ApiKeys;
    if (typeof ApiKeys === 'string') return [ApiKeys];
    return [];
  }, []);

  return (
    <>
      <Header />
      <div className='container'>
        <div id='uptime'>
          {apikeys.map((key) => (
            <UptimeRobot key={key} apikey={key} />
          ))}
        </div>
        <div id='footer'>
          <p>Monitor frequency: <span className='footer-frequency'>5min/run</span></p>
          <p>Made with <span className='heart'>❤</span>&nbsp;by <Link to='https://www.toshiki.dev/' text='安田俊樹@andatoshiki' /></p>
          <p>2022&nbsp;-&nbsp;present&nbsp;&copy;&nbsp;👋&nbsp;-&nbsp;<Link to='mailto:hello@toshiki.dev' text='hello@toshiki.dev' /></p>
        </div>
      </div>
    </>
  );
}

export default App;
